<template>
  <main class="py-24">
    <div class="w-full max-w-xl mx-auto px-4">
      <h2 class="text-center text-lg text-gray-800 font-medium md:text-2xl">
        You're an early bird!
      </h2>
      <div class="mt-6 flex justify-center md:mt-10">
        <adjustments-icon class="h-20 w-auto text-orange-300 md:h-36" />
      </div>

      <div class="mt-10 w-full max-w-md mx-auto text-gray-700 sm:text-center md:mt-16 md:text-lg">
        <p>
          We're working on some awesome home-buying (and selling) features that we think you'll love!
        </p>

        <p class="mt-4">
          For now, you can
          <router-link class="font-medium underline text-lime-600 hover:text-lime-700 transition" to="/properties">
            browse our catalog
          </router-link>
          and let us know what properties you're interested in!
        </p>
      </div>


    </div>
  </main>
</template>

<script>
import AdjustmentsIcon from '@/components/icons/AdjustmentsIcon.vue'

export default {
  components: { AdjustmentsIcon }
}
</script>
